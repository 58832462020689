@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Outfit:wght@100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.header-button {
  position: relative;
  /* Position absolutely within the header */
  top: 10px;
  /* Adjust as needed */
  right: 20px;
  /* Adjust as needed */
  align-items: center;
  vertical-align: middle;
  margin: 0;
  height: 48px;
  width: 48px;
  cursor: pointer;
  background: transparent;
  transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1);
  color: #222222;
  display: inline-flex;
  border: 1px solid #DDDDDD;
  line-height: 20px;
  padding: 10px 10px 10px 10px;
  border-radius: 21px;
  outline: none;
}

.raleway-jun e {
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.header-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.beta-button:hover {
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.18);
}

.menu:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.contact-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.menu-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.about:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.header-button {
  transition: transform 250ms cubic-bezier(0.2, 0, 0, 1), opacity 300ms cubic-bezier(0.2, 0, 0, 1) 100ms, visibility 0ms 150ms;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.2, 0, 0, 1), opacity 300ms cubic-bezier(0.2, 0, 0, 1) 100ms, visibility 0ms 150ms;
}

.header-logo {
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  inline-size: 5156.23;
  display: inline;
  padding: 10px 30px;
  width: 100px;
  height: auto;
}

.header-items {
  display: flex;
  align-items: center;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  /* Space between logo and other items */
  align-items: center;
  padding: 5px 20px;
  position: relative;
}


.about {
  height: 100vh;
  max-width: 700px;
  /* Maximum width of the About div */
  width: 100%;
  padding: 20px;
  /* Padding inside the About div */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
  /* Optional: Add a shadow for better visibility */
  box-sizing: border-box;
  /* Include padding in the element's total width and height */
  margin: 15 auto;
  box-sizing: border-box;
  margin-top: 50px;
  line-height: 1.4;
  font-size: 16px;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  -webkit-box-align: start;
  -ms-flex-align: start;
  /* background-color: #ebe6dcbf;*/
}

.about-container {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 100vh;
  /* Full viewport height */
  padding: 0 20px;
  /* Add padding to the left and right */
  box-sizing: border-box;
  /* Include padding in the element's total width and height */
}

.menu {
  display: none;
  /* Initially hidden */
  flex-direction: column;
  transform: translateX(-100%);
  /* Ensure the menu stays within the viewport */

  align-items: flex-start;
  z-index: 1;
  position: absolute;
  top: 100%;
  /* Position below the header button */
  vertical-align: left;
  height: 80px;
  width: 100px;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
  transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1);
  color: #222222;
  display: inline-flex;
  position: absolute;
  /* Ensure the menu stays in place */
  top: 100%;
  /* Position the menu below the header */
  border: 1px solid #DDDDDD;
  line-height: 20px;
  padding: 10px 10px 10px 10px;
  border-radius: 21px;
  outline: none;
}

.header-button.active+.menu {
  display: flex;
}

.menu-button {
  align-items: center;
  vertical-align: middle;
  border: transparent;
  height: 48px;
  width: auto;
  cursor: pointer;
  background: transparent;
  transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1);
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #222222;
  display: inline-flex;
  position: relative;
  line-height: 20px;
  padding: 10px 10px 10px 10px;
  border-radius: 21px;
  outline: none;
}

.contact-button {
  margin: 0 10px;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #222222;
  background-color: transparent;
  /* Transparent background */
  border: none;
  /* No border */
  padding: 10px 20px;
  /* Vertical and horizontal padding */
  text-align: center;
  /* Center text inside the button */
  text-decoration: none;
  /* No underline */
  display: inline-block;
  /* Allows setting dimensions */
  font-size: 16px;
  /* Text size */
  cursor: pointer;
  /* Cursor indicates it's clickable */
  transition: background-color 0.3s, color 0.3s;
}


.beta-button {
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  border-radius: 21px;
  padding: 10px 20px;
  background-color: #000aff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1em;
  box-shadow: '0 4px 6px rgba(0, 0, 0, 0.1)';
}


canvas {
  width: 100vw;
  height: 100vh;
}

.canvas-style {
  z-index: -1;
  opacity: 0.3;
  filter: blur(1px);
  position: absolute !important;
  top: 0;
  height: 80vh;
}


body,
html {
  height: 100%;
  margin: 0;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html {
  background: #ebe6dcbf;
}

.landing-container {
  min-height: 100%;
  padding-bottom: 50px;
}

#root {
  transition: all 0.1s;
}


.signup {
  opacity: 0.7;
  position: relative;
  margin-top: 400px;
}

.landing-content {
  text-align: center;
}

.title {
  font-size: 3rem;
  backdrop-filter: blur(1px);
  /* Large font size */
  text-align: center;
  /* Center-align the text */
  color: #222222;
  /* Dark gray color */
  margin: 50px 0;
  /* Spacing above and below the title */
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  position: relative;
}

.coming-soon {
  font-size: 1.3em;
  margin-bottom: 50px;
  backdrop-filter: blur(1px);
  margin-top: 50px;
  color: #222222;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}
.coming-soon2 {
  font-size: 1.1em;
  margin-bottom: 50px;
  backdrop-filter: blur(1px);
  margin-top: 50px;
  color: #222222b8;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}

.slogan {
  font-size: 1.25em;
  margin-bottom: 50px;
  backdrop-filter: blur(1px);
  margin-top: 50px;
  position: relative;
  color: #222222;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}

.contact,
.contact-info {
  margin-bottom: 15px;
  font-size: 1.0em;
  margin-bottom: 50px;
  margin-top: 50px;
  margin-left: 2em;
  color: #222222;
  margin-top: 1em;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}


.email-link {
  color: #000aff;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
}

body,
.container,
.row {
  width: 100vw;
  height: 100vh;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  /* background-color: #ebe6dcbf;*/
}

/* Footer Styles */
.footer-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  width: 100%;
  font-size: 0.5em;
  /* Smaller font size */
}

.footer-link {
  color: #000aff;
  text-decoration: none;
  margin: 0 10px;
}

.footer-link:hover {
  text-decoration: underline;
}



@media screen and (max-width: 576px) {
  .container {
    height: 100vh;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .container .mainInfo {
    text-align: center;
  }
}



@media screen and (max-width: 576px) {
  .container .mainInfo .form-subscribe .form {
    margin-left: 0;
    font-size: 0.9em;
    /* Slightly smaller font size for smaller screens */
  }

  .container .mainInfo .form-subscribe button {
    width: 100px;
    /* Adjust button width as needed */
  }
}


.privacy-policy,
.terms-of-service {
  text-align: left;
  margin: 20px;
  font-size: 0.7em;
  padding: 20px;
  /* background-color: #ebe6dcbf; */
  color: #312a1c;
  border-radius: 10px;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.privacy-policy h1,
.terms-of-service h1 {
  font-size: 1.5em;
}